import { memo, ReactNode } from 'react';

import RichText from '@/components/RichText/RichText';

interface Props {
  className?: string;
  ordered?: boolean;
  values: string & ReactNode;
}

function BlockList({ className, ordered, values }: Props) {
  return (
    <RichText className={className} tag={ordered ? 'ol' : 'ul'}>
      {values}
    </RichText>
  );
}

export default memo(BlockList);
