import { memo } from 'react';

import Quote from '@/components/Quote/Quote';

export type Props = {
  className?: string;
  value: string;
  citation?: string;
};

function BlockQuote({ className, value, citation }: Props) {
  return <Quote className={className} quote={value} author={citation} />;
}

export default memo(BlockQuote);
