export enum SocialTypes {
  FACEBOOK = 'facebook',
  TWITTER = 'twitter',
  LINKEDIN = 'linkedin'
}

export default function socialShare(socialMediaType: SocialTypes, url: string, text: string = '') {
  const encodedUrl = encodeURIComponent(String(url));
  const encodedText = encodeURIComponent(String(text));
  const windowProperties = `left=0,top=0,width=570,height=570,menubar=no,toolbar=no,status=no,scrollbars=yes,resizable=yes`;

  switch (socialMediaType) {
    case SocialTypes.FACEBOOK:
      window.open(
        `https://www.facebook.com/sharer.php?u=${encodedUrl}`,
        'Facebook',
        windowProperties
      );
      break;
    case SocialTypes.TWITTER:
      window.open(
        `http://twitter.com/share?url=${encodedUrl}&text=${encodedText}`,
        `Twitter`,
        windowProperties
      );
      break;
    case SocialTypes.LINKEDIN:
      window.open(
        `https://www.linkedin.com/shareArticle?mini=true&url=${encodedUrl}&title=${encodedText}`,
        `LinkedIn`,
        windowProperties
      );
      break;
    default:
      break;
  }
}
