// @ts-nocheck TODO: fix typescript
import { memo, SetStateAction, useEffect, useRef, useState } from 'react';
import classnames from 'classnames';
import gsap from 'gsap';
import ScrollTrigger from 'gsap/dist/ScrollTrigger';
import useSWR from 'swr';

import styles from './PortfolioGrid.module.scss';

import { CompaniesNode } from '@/types';

import { fetchCompaniesEndpoint, fetcher } from '@/graphql/fetcher';

import PortfolioFilters from '../PortfolioFilters/PortfolioFilters';
import PortfolioItem, { Props as ItemProps } from '../PortfolioItem/PortfolioItem';

export type Props = {
  className?: string;
  query?: {};
};

const initCategories: { [key: string]: { slug: string; name: string }[] } = {
  industry: [],
  status: [
    {
      slug: 'acquired',
      name: 'Acquired'
    },
    {
      slug: 'early-stage',
      name: 'Early-Stage'
    },
    {
      slug: 'early-growth',
      name: 'Early growth'
    },
    {
      slug: 'public',
      name: 'Public'
    }
  ]
};

function PortfolioGrid({ className, query }: Props) {
  const [categories, setCategories] = useState(initCategories);
  const emptyFilters = { industry: [], status: [] };
  const gridContainer = useRef(null);
  const itemsRef = useRef<HTMLDivElement[]>([]);
  const filtersRef = useRef<HTMLDivElement[]>([]);
  const [selectedFilters, setSelectedFilters] = useState<{ [key: string]: string[] }>(emptyFilters);
  const [filteredList, setFilteredList] = useState<ItemProps[]>([]);
  const [selectedFiltersNumber, setSelectedFiltersNumber] = useState<number>(0);
  const [list, setList] = useState([]);
  const { data, error } = useSWR(fetchCompaniesEndpoint, fetcher);

  const handleFilters = (
    filters: SetStateAction<{ [key: string]: string[] }>,
    selectedFiltersNumber: number
  ) => {
    setSelectedFilters(filters);
    setSelectedFiltersNumber(selectedFiltersNumber);
  };

  useEffect(() => {
    if (data) {
      const newCategories = initCategories;
      const newList = data?.companies?.nodes?.map((company: CompaniesNode) => {
        // Get industry
        company?.companyCategories?.nodes?.map((industry) => newCategories.industry.push(industry));

        return {
          name: company?.title,
          categories: {
            industry:
              company?.companyCategories?.nodes && company?.companyCategories?.nodes.length > 0
                ? company?.companyCategories?.nodes[0]
                : {},

            status: company?.companies?.status?.length
              ? company?.companies?.status?.map((status) => {
                  return {
                    slug: status.replace(/\s+/g, '-').toLowerCase(),
                    name: status
                  };
                })
              : []
          },
          stockTicker: company?.companies?.stockTicker,
          logo: {
            src: company?.companies?.logo?.sourceUrl,
            alt: company?.companies?.logo?.altText
          },
          text: company?.excerpt,
          link: company?.uri
        };
      });

      // Remove duplicate values
      newCategories.industry = newCategories?.industry.filter(
        (value, index, self) => index === self.findIndex((t) => t.slug === value.slug)
      );

      setList(newList);
      setCategories((prevState) => ({ ...prevState, ...newCategories }));
    }

    gsap.set(filtersRef.current, { autoAlpha: 0 });
    setTimeout(() => {
      ScrollTrigger.create({
        trigger: gridContainer.current,
        start: 'top 80%',
        once: true,
        onEnter: () => {
          gsap.to(filtersRef.current, { autoAlpha: 1, duration: 0.568, ease: 'linear' });
        }
      });
    }, 200);
  }, [data]);

  useEffect(() => {
    let combinedFiltersItems: ItemProps[] = [];
    // loop through selected main filters
    let selectedCatNumber = 0;
    Object.keys(selectedFilters).forEach((filter) => {
      let singleFilterItems: ItemProps[] = [];
      const number = selectedFilters[filter].length;
      // loop through subfilters and create an array (singleFilterItems) of items that have that filter
      selectedFilters[filter].some((cat) =>
        list.some((item) => {
          // check if categories filter is an array of multiple categories (partner)
          singleFilterItems =
            Array.isArray(item.categories[filter]) &&
            item.categories[filter].some((e) => e.slug === cat)
              ? [...singleFilterItems, item]
              : item.categories[filter].slug === cat
              ? [...singleFilterItems, item]
              : [...singleFilterItems];
        })
      );
      selectedFilters[filter].length > 0 && selectedCatNumber++;
      // if a filter is selected and items array is empty then no match should be found
      // if no match was found then items are an empty array
      combinedFiltersItems = !(singleFilterItems.length === 0 && number > 0)
        ? (combinedFiltersItems.length === 0 && selectedCatNumber < 2) ||
          singleFilterItems.length === 0
          ? // if one filter is selected, then add singleFilterItems to combined array
            [...combinedFiltersItems, ...singleFilterItems]
          : // if more then 1 filters selected, then merge arrays by keeping items that exist in all the arrays, ie intersection
            [...combinedFiltersItems.filter((d) => singleFilterItems.indexOf(d) !== -1)]
        : [];
    });
    // if no filter is selected, display initial list of items
    if (selectedFiltersNumber > 0) {
      setFilteredList(combinedFiltersItems);
    } else {
      setFilteredList(list);
    }
  }, [selectedFilters, selectedFiltersNumber, list]);

  useEffect(() => {
    if (gridContainer.current) {
      const items = Array.from(gridContainer.current.children);
      if (!items.length) {
        return;
      } else {
        gsap.set(items, { y: 20, autoAlpha: 0 });
      }
      const batchList = ScrollTrigger.batch(items, {
        start: 'top 80%',
        batchMax: 4,
        onEnter: (batch) => {
          gsap.to(batch, { autoAlpha: 1, duration: 0.67, stagger: 0.067, ease: 'linear' });
          gsap.to(batch, { y: 0, duration: 0.5, stagger: 0.067, ease: 'ease1' });
        }
      });

      return () => {
        batchList?.forEach((trigger) => trigger.kill());
      };
    }
  }, [filteredList]);

  if (error) {
    return null;
  }

  return (
    <div className={classnames(styles.PortfolioGrid, className)}>
      <PortfolioFilters
        ref={filtersRef}
        onFilterChange={handleFilters}
        categories={categories}
        result={filteredList.length}
      />

      {filteredList.length ? (
        <div className={styles.grid} ref={gridContainer}>
          {filteredList
            .sort((a, b) =>
              query.order === 'asc' ? a.name.localeCompare(b.name) : b.name.localeCompare(a.name)
            )
            .map((companyItem, index) => {
              return (
                <div
                  key={index}
                  ref={(el: HTMLDivElement) => (itemsRef.current[index] = el)}
                  className={styles.gridItem}
                >
                  <PortfolioItem {...companyItem} />
                </div>
              );
            })}
        </div>
      ) : (
        <div className={styles.noMatch}>No match found</div>
      )}
    </div>
  );
}

export default memo(PortfolioGrid);
