import { memo } from 'react';
import classnames from 'classnames';

import styles from './SocialShare.module.scss';

import { getMailTo } from '@/utils/basic-functions';
import share, { SocialTypes } from '@/utils/share';

import ThemedButton, { ButtonType } from '../ThemedButton/ThemedButton';

export type Props = {
  className?: string;
};

function SocialShare({ className }: Props) {
  return (
    <div className={classnames(styles.SocialShare, className)}>
      <p className={styles.text}>Share</p>
      <span className={styles.separator}></span>
      <ThemedButton
        className={styles.button}
        onClick={() => share(SocialTypes.TWITTER, window.location.href, 'Twitter')}
        theme={ButtonType.Icon}
        text="Twitter"
      />

      <ThemedButton
        className={styles.button}
        onClick={() => share(SocialTypes.LINKEDIN, window.location.href, 'Linkedin')}
        theme={ButtonType.Icon}
        text="Linkedin"
      />

      <ThemedButton
        className={classnames(styles.emailAnchor, styles.button)}
        theme={ButtonType.Icon}
        text={'Share by email'}
        url={getMailTo({
          email: 'xyz@abc.com',
          subject: `Eclipse VC - ${typeof document !== 'undefined' ? window.document.title : ''}`,
          body: `Check this website ${typeof document !== 'undefined' ? window.location.href : ''}`
        })}
      />
    </div>
  );
}

export default memo(SocialShare);
